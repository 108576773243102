import React, { useState } from "react";
import {
  RegistroWrapper,
  RegistroFormWrapper,
  ContainerTitle,
  ContainerTitleText,
  StyledForm,
  ContainerLeft,
  StyledButton,
  DivInput,
  StyledLabel,
  StyledInput,
  PhoneContainer,
  StyledSelect,
  StyledInputPhone,
  StyledTextArea
} from "./styles";
import {
  setEmpresa,
  guardaEmpresa,
  agregaEmpresa,
} from "../../redux/empresa/empresaActions";
import { useToasts } from "react-toast-notifications";
import { useDispatch, useSelector } from "react-redux";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import { uploadImage } from "../../services/firebase/empresas/index";

const countryCodes = [
  { name: "Perú", code: "+51" },
  // { name: "México", code: "+52" },
  // { name: "Argentina", code: "+54" },
  // { name: "Chile", code: "+56" },
  // { name: "Colombia", code: "+57" },
  // { name: "España", code: "+34" },
];

export const Empresa = () => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const empresa = useSelector((state) => state.empresa);
  const sesion = useSelector((state) => state.sesion);
console.log('emprsa',empresa)
  const [selectedCode, setSelectedCode] = useState(countryCodes[0].code); // Default Peru (+51)
  

  const codigosPaises = {
     "51": /^[9]\d{8}$/,   // Perú
    // "54": /^[9]\d{9}$/,   // Argentina
    // "55": /^[9]\d{8}$/,   // Brasil
    // "56": /^[9]\d{8}$/,   // Chile
    // "57": /^[3]\d{9}$/,   // Colombia
    // "58": /^[4]\d{9}$/,   // Venezuela
    
    // "52": /^[1]\d{9}$/,   // México
    // "53": /^[5]\d{7}$/,   // Cuba
    // "506": /^[6789]\d{7}$/, // Costa Rica
    // "507": /^[6]\d{7}$/,   // Panamá
    // "591": /^[67]\d{7}$/,  // Bolivia
    // "593": /^[9]\d{8}$/,   // Ecuador
    // "595": /^[9]\d{8}$/,   // Paraguay
    // "598": /^[9]\d{7}$/,   // Uruguay
    // "503": /^[67]\d{7}$/,  // El Salvador
    // "504": /^[3789]\d{7}$/, // Honduras
    // "505": /^[85]\d{7}$/,   // Nicaragua
    // "502": /^[34567]\d{7}$/, // Guatemala
};

const extraerTelefono = (numeroCompleto) => {
    if (!numeroCompleto) return { codigoPais: "", telefono: "" };

    for (const [codigo, regex] of Object.entries(codigosPaises)) {
        if (numeroCompleto.startsWith(codigo)) {
            const telefono = numeroCompleto.slice(codigo.length);
            if (regex.test(telefono)) {
                return { codigoPais: codigo, telefono };
            }
        }
    }

    return { codigoPais: "", telefono: numeroCompleto }; // Si no encuentra código, devuelve el número completo
};
  // const codigoPais = extraerTelefono(empresa.telefono).codigoPais;
  // const telefono = extraerTelefono(empresa.telefono).telefono;


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (empresa.id) dispatch(guardaEmpresa(empresa));
      else dispatch(agregaEmpresa(empresa));
      addToast("Empresa guardada satisfactoriamente", {
        appearance: "success",
      });
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    }
  };

  const handleChange = (e) => {
    dispatch(setEmpresa({ ...empresa, [e.target.name]: e.target.value }));
  };

  const handleCountryCodeChange = (e) => {
    setSelectedCode(e.target.value);
    dispatch(setEmpresa({ ...empresa, telefono: e.target.value + empresa.telefono?.split(" ")[1] || "" }));
  };

  const handlePhoneChange = (e) => {
    const phoneNumber = e.target.value.replace(/\D/g, "");
    dispatch(setEmpresa({ ...empresa, telefono: phoneNumber }));
  };

  const onFileChange = async (e) => {
    const file = e.target.files[0];
    const fileUrl = await uploadImage(file);
    dispatch(setEmpresa({ ...empresa, logoUrl: fileUrl }));
  };

  if (sesion.loading || sesion.loading === undefined)
    return <div>...procesando</div>;
  if (sesion.error) return <div>{`Hubo un error ${sesion.error}`}</div>;

  return (
    <RegistroWrapper>
      <ContainerTitle>
        <ContainerTitleText>Datos Empresa</ContainerTitleText>
      </ContainerTitle>
      <RegistroFormWrapper>
        <form onSubmit={handleSubmit}>
          <StyledForm>
            <DivInput>
              <StyledLabel>Razon Social</StyledLabel>
              <StyledInput
                name="razonSocial"
                className="form__input"
                placeholder="Razon Social"
                type="text"
                autoComplete="off"
                value={empresa.razonSocial}
                onChange={handleChange}
                required
              />
            </DivInput>
            <DivInput>
              <StyledLabel>RUC</StyledLabel>
              <StyledInput
                name="ruc"
                className="form__input"
                placeholder="RUC"
                type="text"
                autoComplete="off"
                value={empresa.ruc}
                onChange={handleChange}
                required
              />
            </DivInput>
            <DivInput>
              <StyledLabel>Correo Google</StyledLabel>
              <StyledInput
                name="googleCalendar"
                className="form__input"
                placeholder="Google Calendar"
                type="text"
                autoComplete="off"
                value={empresa.googleCalendar}
                onChange={handleChange}
                required
              />
            </DivInput>

            <DivInput>
              <StyledLabel>Prompt</StyledLabel>
              <StyledTextArea
                name="prompt"
                className="form__input"
                placeholder="PROMPT"
                type="text"
                autoComplete="off"
                value={empresa.prompt}
                onChange={handleChange}
                required
              />
        
        </DivInput>
        <DivInput>
        <StyledLabel>Teléfono</StyledLabel>
        <StyledInputPhone
      name="telefono"
      placeholder="123456789"
      type="tel"
      autoComplete="off"
      value={empresa.telefono}
      onChange={handlePhoneChange}
      required
    />
  {/* <PhoneContainer> */}
    {/* <StyledSelect
      name="countryCode"
      value={codigoPais}
      onChange={handleCountryCodeChange}
    >
      {countryCodes.map((country) => (
        <option key={country.code} value={country.code}>
          {country.name} ({country.code})
        </option>
      ))}
    </StyledSelect> */}
    
  {/* </PhoneContainer> */}

        </DivInput>
  












            <DivInput>
              <StyledLabel>Seleccione</StyledLabel>
              <div className="button-wrapper">
                <span className="label">
                  {<AddPhotoAlternateIcon />} Logo
                </span>
                <input
                  type="file"
                  className="upload-box"
                  onChange={onFileChange}
                />
              </div>
            </DivInput>
          </StyledForm>
          <ContainerLeft>
            <StyledButton className="form__button" type="submit">
              Guardar
            </StyledButton>
          </ContainerLeft>
        </form>
      </RegistroFormWrapper>
    </RegistroWrapper>
  );
};
