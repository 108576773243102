import styled from "styled-components";
import { Card, Accordion } from "react-bootstrap";
import AppBar from "@material-ui/core/AppBar";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import { dispositivo } from "../../components/Dispositivos";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const MainContainer = styled.div`
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  align-items: center;
  background: #f8f8f8;
  justify-content: center; 
`;

export const ContainerTitle = styled.div`
  color: #000;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  padding-top: 2%;
`;

export const ContainerTitleText = styled.div`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #ffffff;
  background: #0c8de3;
  border: 2px solid #0c8de3;
  width: 100%;
`;

export const ContainerBody = styled.div`
  background: #fff;
  border-radius: 8px;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin-top: 2%;
  margin-bottom: 5%;
  @media ${dispositivo.tablet} {
    padding-right:10%;
    padding-left:10%;

  }
`;

export const CardBody = styled.div`
padding:1em;
`;

export const ContainerLeft = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: right;
`;

export const CotenedorFechaNac = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: right;
  padding-right: 33%;
  @media ${dispositivo.mobileM} {
    padding-right: 46%;
  }
  @media ${dispositivo.mobileL} {
    padding-right: 69%;
  }
  @media ${dispositivo.laptop} {
    padding-right: 76%;
  }
`;

export const SDatePicker = styled(DatePicker)`
  border-radius: 10px;
  background-color: #64bdff30;
  border: none;
  outline: none;
  color: #0D0808;
  margin: 0 0 0.8em;
  line-height: 2.5;
  font-size: 1rem;
  width: 12.5rem;
  heigth: 2rem;
  padding-left: 0.5em;
  &::placeholder {
  color: #04000060;
    fonst-size: 0.5rem;
  }
  &:focus{
    border: 2px solid #0060b0;
  }
  @media ${dispositivo.tablet} {
    
  }
`;

export const STabs = styled(Tabs)`
  width: 100%;
  padding: 0 5% 5% 5%;
  justify-content: center;
  text-align: center;
  align-items: center;
`;

export const STabList = styled(TabList)`
  list-style-type: none;
  padding-bottom: 1rem;
  display: flex;
  margin: 0;
  justify-content: center;
  text-align: center;
  align-items: center;
`;
STabList.tabsRole = "TabList";

export const STab = styled(Tab)`
  margin-right: 4px;
  color: #fff;
  padding: 7px;
  user-select: none;
  cursor: arrow;
  width: 50%;
  cursor: pointer;
  background: #9a9a9a;
  border-radius: 4px;

  justify-content: center;
  text-align: center;
  align-items: center;
  &.is-selected {
    color: white;
    background: #0c8de3;
    border-radius: 4px;
  }
  &:hover {
    color: white;
    background: #0c8de3;
    border-radius: 4px;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 0, 255, 0.5);
  }
`;
STab.tabsRole = "Tab";

export const STabPanel = styled(TabPanel)`
  flex: 1;
  /* max-height: 370px; */
  ::-webkit-scrollbar {
    width: 20px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #0c8de3;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #fff;
  }
  @media ${dispositivo.tablet} {
    &.is-selected {
      display: block;
    }
    display: none;
    padding: 4px;
    margin-top: -5px;
    min-height: 40vh;
  }
`;
STabPanel.tabsRole = "TabPanel";

export const FormStyled = styled.form`
  justify-content: left;
  text-align: center;
  align-items: center;
`;

export const FormGroupField = styled.div`
  width:100%;
`;
export const LabelStyled = styled.label`
  text-align: left;
  font-size: 0.875rem;
  color:#040404;
  font-weight: bold;
  @media ${dispositivo.mobileL} {
    font-size: 1rem; 
  }
  

`;

export const Tabla = styled.table`
  width: 100%;
`;

export const TablaHeader = styled.thead`
  background: #fff;
  padding-left: 1px;
  padding-right: 7px;
  padding-top: 16px;
  padding-bottom: 16px;
  font-size: 16px;
`;

export const TablaHeaderText = styled.td`
  width: 60%;
  
`;
export const TablaHeaderLabel = styled.th`
  font-size: 1rem;
  width: 10%;
  font-weight: normal;
  color: #707070;
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: left;
`;

export const StyledInput = styled.input`
  border-radius: 10px;
  background-color: #64bdff30;
  border: none;
  outline: none;
  color: #0D0808;
  margin: 0 0 0.8em;
  line-height: 2.5;
  font-size: 0.75rem;
  width: 100%;
  height: 2rem;
  padding-left: 0.5em;
  &::placeholder {
  color: #04000060;
    fonst-size: 0.5rem;
  }
  &:focus{
    border: 2px solid #0060b0;
  }
  @media ${dispositivo.mobileL} {
    font-size: 1rem;
  }
`;
export const StyledInput2 = styled(StyledInput)`
width: 12.5rem;
`;
export const StyledSelect = styled.select`
  display: felx;
  border-radius: 10px;
  background-color: #64bdff30;
  border: none;
  outline: none;
  color: #0D0808;
  margin: 0 0 0.8em;
  line-height: 2.5;
  font-size: 0.75rem;
  width: 12.5rem;
  text-align: end;
  height: 2rem;
  &::placeholder {
  color: #04000060;
    font-size: 0.5rem;
  }
  &:focus{
    border: 2px solid #0060b0;
  }
  
  @media ${dispositivo.mobileL} {
    font-size: 1rem;
  }
`;

export const ContainerPatologias = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  justify-content: center;

  @media ${dispositivo.tablet} {
    display: flex;
    vertical-align: top;
    align-items: top;
  }
`;

export const ContainerPatologia = styled.div`
  display: block;
  height: 100%;
  width: 100%;
  text-align: left;
  margin-left: 3%;
  margin-right: 3%;
  margin-bottom: 3%;
  font-weight: normal;
  color: #707070;
  padding: 4px;
  font-size: 1rem;
  border-radius: 10px;
  border: 2px solid #0c8de3;

  @media ${dispositivo.mobileL} {
    margin-left: 1%;
    margin-right: 1%;
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 15px;

    margin-left: 17px;
    margin-right: 17px;
  }
`;

export const ContainerPatologiaTitulo = styled.h2`
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  padding: 10px;
  margin-top: 5px;
  color: #0c8de3;
`;
export const ContainerBtn = styled.div`
  display: flex;
  justify-content: end;
  padding-top: 1em;
`;
export const StyledButton = styled.button`
  height: 2.25rem;
  font-size: 0.9rem;  
  width: 100%;
  border-radius: 0.8rem;      
  text-transform: uppercase;
  cursor: pointer;
  font-width: bold;
  background-color: #0060b0;
  color: #ffffff;
  border: none;
  &:hover{
    color:#000000;
    background-color: #0c8de390;
    font-width: bold;
  }
  @media ${dispositivo.mobileM}{
    width: 7rem; 
  }
  @media ${dispositivo.tablet}{
    width: 10rem;
    font-size: 1rem;  
  }  
  @media ${dispositivo.laptop} {      
    width: 12.5rem;
  }
  
`;

export const TextoAlergia = styled.li`
  background: #707070;
  padding: 2%;
`;

export const StyledSelect1 = styled.select`
  width: 30%;
  background-color: #64bdff30;
  border: none;
  outline: none;
  color: #0D0808;
  margin: 0 0 0.8em;
  line-height: 2.5;
  font-size: 0.75rem;
  text-align: end;
  height: 2rem;
  float: left;
  &::placeholder {
    color: #04000060;
    font-size: 0.5rem;
  }
  &:focus{
    border: 2px solid #0060b0;
  }
  @media ${dispositivo.mobileL} {
    font-size: 1rem;
  }
`;

export const StyledInput3 = styled(StyledInput)`
  width: 65%;
  float: right;
`;