import { db } from "../../services/firebase/Config";
import moment from 'moment-timezone';
import {
  NEW_PACIENTE,
  SET_PACIENTE,
  ADD_PATOLOGIA,
  REMOVE_PATOLOGIA,
  UPDATE_PATOLOGIA,
} from "./pacienteTypes";

import { addPacienteInArray } from "../pacientes/pacientesActions";
import { httpRequest, httpSuccess, httpFailure } from "../sesion/sesionActions";
import { crearCuenta } from "../cuenta/cuentaActions";

export const addPatologia = (patologia) => {
  return {
    type: ADD_PATOLOGIA,
    payload: patologia,
  };
};

export const removePatologia = (patologia) => {
  return {
    type: REMOVE_PATOLOGIA,
    payload: patologia,
  };
};

export const updatePatologia = (patologia) => {
  return {
    type: UPDATE_PATOLOGIA,
    payload: patologia,
  };
};

export const setPaciente = (payload) => {
  return {
    type: SET_PACIENTE,
    payload,
  };
};

export const newPaciente = () => {
  return {
    type: NEW_PACIENTE,
  };
};
const fechaACadena = (fecha) => {
  let f;
  if (fecha === null || fecha === undefined || fecha === "" || isNaN(fecha))
    f = new Date();
  else f = fecha;
  const a = f.getFullYear();
  const m = (f.getMonth() + 1).toString().padStart(2, "0");
  const d = f.getDate().toString().padStart(2, "0");
  return `${a}-${m}-${d}`;
};


var pacienteConverter = {

  toFirestore: function (paciente) {
    let fechaparams = paciente.fecNac.split("-");
    console.log('fechaNac', paciente.fecNac)
    let fecNac = new Date(fechaparams[0], fechaparams[1] - 1, fechaparams[2]);
    console.log('fechaNacDate', fecNac)

    return {
      sexo: paciente.sexo,
      id: paciente.id,
      tipDoc: paciente.tipDoc,
      numDoc: paciente.numDoc,
      apellidos: paciente.apellidos,
      nombres: paciente.nombres,
      fecNac: fecNac,
      telefono: paciente.telefono,
      direccion: paciente.direccion,
      correo: paciente.correo,
      patologias: paciente.patologias,
      estado: paciente.estado,
      empresaId: paciente.empresaId,
      token: paciente.token
    };
  },
  fromFirestore: function (snapshot, options) {
    const data = snapshot.data(options);
    return {
      sexo: data().sexo,
      id: data().id,
      tipDoc: data().tipDoc,
      numDoc: data().numDoc,
      apellidos: data().apellidos,
      nombres: data().nombres,
      fecNac: data().fecNac ? fechaACadena(data.fecNac().toDate()) : fechaACadena(new Date()),
      telefono: data().telefono,
      direccion: data().direccion,
      correo: data().correo,
      patologias: data().patologias,
      estado: data().estado,
      empresaId: data().empresaId,
    };
  },
};

export const guardaPaciente = (paciente) => {
  return function (dispatch) {
    let pacienteRef;
    const currentTime = moment().tz("America/Lima").format();
    if (paciente.id) {
      pacienteRef = db.collection("pacientes").doc(paciente.id);
      paciente.updated_at = currentTime;
    } else {
      pacienteRef = db.collection("pacientes").doc();
      paciente.created_at = currentTime;
    }
    dispatch(httpRequest());
    pacienteRef
      .withConverter(pacienteConverter)
      .set(paciente)
      .then(() => {
        paciente.id = pacienteRef.id;
        dispatch(httpSuccess());
        dispatch(setPaciente(paciente));
      })
      .catch((err) => {
        dispatch(httpFailure(err));
      });
  };
};

export const addPaciente = (paciente) => {
  return function (dispatch) {
    var pacienteRef = db.collection("pacientes").doc();
    dispatch(httpRequest());
    paciente.id = pacienteRef.id;
    pacienteRef
      .withConverter(pacienteConverter)
      .set(paciente)
      .then(() => {
        dispatch(crearCuenta(paciente));
        dispatch(addPacienteInArray(paciente));
        dispatch(httpSuccess());
      })
      .catch((err) => {
        dispatch(httpFailure(err));
      });
  };
};
